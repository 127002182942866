import Logger from '@/utils/logger';

const logger = new Logger({ caller: 'managers.FeatureFlags.index' });

const getQueryStringValue = (name: string): string|null => {
  const qspValue = new URLSearchParams(window.location.search).get(name);
  return qspValue;
};

const parseFeatureFlagValue = (
  value: boolean|number|string,
  contentType: string,
): boolean|number|string|Array<string> => {
  if (value === 'true') {
    return true;
  } else if (value === 'false') {
    return false;
  } else if (contentType === 'list' && typeof value === 'string') {
    return value.split(',');
  } else if (typeof value === 'string' && /^[0-9.]+$/.test(value)) {
    return parseFloat(value);
  }
  return value;
};

class ClientSideFeatureFlag {
  value: string | number | boolean | Array<string>;
  sourceValues: {
    name: string;
    resolvedFrom: 'default' | 'queryString' | 'sessionStorage';
  };

  constructor(
    name: string,
    contentType: string,
    defaultValue: boolean|number|string|Array<string>,
  ) {
    this.value = defaultValue;
    this.sourceValues = {
      name: name,
      resolvedFrom: 'default',
    };

    if (typeof window === 'undefined') return;

    const qspValue = getQueryStringValue(name);
    const storageValue = sessionStorage.getItem(name);

    if (qspValue !== null) {
      this.sourceValues.resolvedFrom = 'queryString';
      this.value = parseFeatureFlagValue(qspValue, contentType);
    } else if (storageValue !== null) {
      this.sourceValues.resolvedFrom = 'sessionStorage';
      this.value = parseFeatureFlagValue(storageValue, contentType);
    }

    logger.info(`ClientSideFeatureFlag \`${name}\` (set by ${this.sourceValues.resolvedFrom}) = ${JSON.stringify(this.value)}`);
  }
}

const FeatureFlags = {
  // This const is intended to be updated as we add/remove global feature flags.
  // Env vars without the 'NEXT_PUBLIC_' prefix are only processed server-side.
  // It's recommended to access these via `getStaticProps`, but `getServerSideProps` also works.
  // Feature flags should be opt-in, meaning they should resolve to `false` if not defined.
  isGamesPhase: (process.env.NEXT_PUBLIC_FEATURE_FLAG_IS_GAMES_PHASE === 'true'),
};

export default FeatureFlags;
export {
  ClientSideFeatureFlag,
};
